import { useState, useEffect } from 'react'
import ApolloClient from 'apollo-boost'
import { gql } from 'apollo-boost'
import { serverUrl } from '../api/api'

const client = new ApolloClient({
  uri: `${serverUrl}/graphql`,
})

const PRODUCTS_QUERY = gql`
  {
    products {
      _id
      main_name
      properties
      item_image
      item_price
      price
    }
    materials {
      _id
      price
    }
  }
`

const PRODUCT_CREATE = gql`
  mutation (
    $main_name: String!
    $properties: String!
    $item_image: String!
    $item_price: String!
    $price: String!
  ) {
    createProduct(
      main_name: $main_name
      properties: $properties
      item_image: $item_image
      item_price: $item_price
      price: $price
    ) {
      _id
      main_name
      properties
      item_image
      item_price
      price
    }
  }
`

const PRODUCT_DELETE = gql`
  mutation ($_id: String!) {
    deleteProduct(_id: $_id) {
      _id
      main_name
      properties
      item_image
      item_price
      price
    }
  }
`

const PRODUCT_UPDATE = gql`
  mutation (
    $id: String!
    $main_name: String!
    $properties: String!
    $item_image: String!
    $item_price: String!
    $price: String!
  ) {
    updateProduct(
      _id: $id
      main_name: $main_name
      properties: $properties
      item_image: $item_image
      item_price: $item_price
      price: $price
    ) {
      _id
      main_name
      properties
      item_image
      item_price
      price
    }
  }
`

const MATERIAL_UPDATE = gql`
  mutation ($id: String!, $price: String!) {
    updateMaterial(_id: $id, price: $price) {
      _id
      price
    }
  }
`

export const useData = () => {
  const [data, setData] = useState({
    products: [],
    materials: [],
  })

  const getData = () => {
    client
      .query({
        query: PRODUCTS_QUERY,
      })
      .then((result) => {
        setData(result.data)
      })
  }

  const createProduct = (product) => {
    client.mutate({
      mutation: PRODUCT_CREATE,
      variables: {
        main_name: product.main_name,
        properties: product.properties,
        item_image: product.item_image,
        item_price: product.item_price,
        price: product.price,
      },
      update: (store, { data: { createProduct } }) => {
        const data = store.readQuery({ query: PRODUCTS_QUERY })
        data.products.push(createProduct[0])
        store.writeQuery({ query: PRODUCTS_QUERY, data: data })
        getData()
      },
    })
  }

  const updateProduct = (product) => {
    client.mutate({
      mutation: PRODUCT_UPDATE,
      variables: {
        id: product.id,
        main_name: product.main_name,
        properties: product.properties,
        item_image: product.item_image,
        item_price: product.item_price,
        price: product.price,
      },
      update: () => {
        getData()
      },
    })
  }

  const updateMaterial = (product) => {
    client.mutate({
      mutation: MATERIAL_UPDATE,
      variables: {
        id: product.id,
        price: product.price,
      },
      update: () => {
        getData()
      },
    })
  }

  const deleteProduct = async (id) => {
    const productId = id

    await client.mutate({
      mutation: PRODUCT_DELETE,
      variables: {
        _id: productId,
      },
      update: (store) => {
        const data = store.readQuery({ query: PRODUCTS_QUERY })

        const index = data.products.findIndex(
          (product) => product._id === productId
        )
        if (index !== -1) {
          data.products.splice(index, 1)
        }

        store.writeQuery({ query: PRODUCTS_QUERY, data: data })
        setData(data)
      },
    })
  }

  useEffect(() => {
    getData()
  }, [])

  return {
    data,
    getData,
    createProduct,
    deleteProduct,
    updateProduct,
    updateMaterial,
  }
}
