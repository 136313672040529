import React from 'react'

const NotFound = () => {
  return (
    <div className='page-container'>
      <h1>Упс...</h1>
      <h2>Ошибка 404</h2>
      <p>Такая страница не найдена</p>
    </div>
  )
}

export default NotFound
