// Old server URLS
// uri: "https://magpack-backend.herokuapp.com/graphql",
// uri: "https://magpack-backend.herokuapp.com/",

// Real server URL
export const serverUrl = 'https://magpack.space'

// Local server URL for local development of needed backend changes
// export const serverUrl = 'http://localhost:4000'

export const secretKey =
  'ewrjcvfiuy3459875hkjdsf97w54flsdakjfasd1!andreimagpack'
