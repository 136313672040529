import React, { useEffect, useState } from 'react'
import LoginIcon from '@mui/icons-material/Login'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

const year = new Date().getFullYear()

const Footer = () => {
  const [adminUrl, setAdminUrl] = useState('/login')
  const location = useLocation()

  useEffect(() => {
    if (
      localStorage.getItem('apiKey') &&
      localStorage.getItem('apiKey').length > 0
    ) {
      setAdminUrl('/admin-edit-products')
    } else {
      setAdminUrl('/login')
    }
  }, [setAdminUrl])

  return (
    <footer className='footer'>
      <div className='container'>
        <div className='footer-contents'>
          © МагПак 2008-{year}
          &nbsp;&nbsp;&nbsp;
          {location.pathname !== '/login' &&
            location.pathname !== '/admin-products-edit' && (
              <Link to={adminUrl}>
                <LoginIcon htmlColor='white' />
              </Link>
            )}
        </div>
      </div>
    </footer>
  )
}

export default Footer
