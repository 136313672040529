import React, { useEffect, useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useData } from '../hooks/useData'

import LoadingContext from '../loading.context'

import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import AddIcon from '@mui/icons-material/Add'
import Button from '@mui/material/Button'
import EditProductForm from './EditProductForm'
import DeleteProduct from './DeleteProduct'

const EditProducts = () => {
  const setLoading = useContext(LoadingContext)[1]
  const navigate = useNavigate()
  const {
    data,
    getData,
    createProduct,
    updateProduct,
    deleteProduct,
    updateMaterial,
  } = useData()
  const [editProductOpened, setEditProductOpened] = useState(false)
  const [currentProduct, setCurrentProduct] = useState({
    _id: null,
    main_name: '',
    properties: '',
    item_price: '',
    price: '',
  })
  const [material, setMaterial] = useState(false)
  const [deleteProductOpened, setDeleteProductOpened] = useState(false)

  const onClickDeleteProductButton = (product) => {
    setCurrentProduct(product)
    setDeleteProductOpened(true)
  }

  const onClickAddProductButton = () => {
    setMaterial(false)
    setCurrentProduct({
      _id: null,
      main_name: '',
      properties: '',
      item_price: '',
      price: '',
    })
    setTimeout(() => {
      setEditProductOpened(true)
    })
  }

  const onClickEditProductButton = (product) => {
    setMaterial(false)
    setCurrentProduct(product)
    setTimeout(() => {
      setEditProductOpened(true)
    })
  }

  const onClickEditMaterialButton = (material) => {
    setCurrentProduct(material)
    setMaterial(true)
    setTimeout(() => {
      setEditProductOpened(true)
    })
  }

  useEffect(() => {
    setLoading(true)
  }, [setLoading, data])

  useEffect(() => {
    if (!localStorage.getItem('apiKey').length) {
      navigate('/login')
    }
    setTimeout(function () {
      localStorage.removeItem('apiKey')
      navigate('/login')
    }, 2400000)
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }, [navigate, data, setLoading])

  return (
    <div className='page-container edit-products-page'>
      <div className='heading'>
        <h2>Редактирование товаров</h2>
        <Button
          onClick={onClickAddProductButton}
          variant='contained'
          className='add-product-button'
        >
          <AddIcon />
          &nbsp; Добавить товар
        </Button>
      </div>

      {data.products.map((product) => (
        <div key={product._id} className='col col-l-6 col-s-12 card item'>
          <div className='item-description'>
            <h4>{product.main_name}</h4>
            <div className='item-properties'>{product.properties}</div>
            <div className='item-price'>
              {product.item_price}
              <span className='price-value' style={{ display: 'none' }}>
                {product.price}
              </span>
            </div>
          </div>
          <div className='control-buttons'>
            <Stack direction='row' spacing={1}>
              <IconButton
                onClick={() => onClickEditProductButton(product)}
                aria-label='edit product'
              >
                <EditIcon color='primary' />
              </IconButton>
              <IconButton
                onClick={() => onClickDeleteProductButton(product)}
                aria-label='delete product'
              >
                <DeleteIcon color='error' />
              </IconButton>
            </Stack>
          </div>
        </div>
      ))}

      {editProductOpened && (
        <EditProductForm
          setModalOpened={setEditProductOpened}
          modalOpened={editProductOpened}
          createProduct={createProduct}
          updateProduct={updateProduct}
          product={currentProduct}
          material={material}
          updateProducts={getData}
          updateMaterial={updateMaterial}
        />
      )}

      <DeleteProduct
        setModalOpened={setDeleteProductOpened}
        modalOpened={deleteProductOpened}
        deleteProduct={deleteProduct}
        product={currentProduct}
        updateProducts={getData}
      />

      <div className='heading'>
        <h2>Цена за кг продукции</h2>
      </div>

      {data.materials.map((material) => (
        <div
          key={material._id}
          className='col col-l-6 col-s-12 card item material-card'
        >
          <div className='item-description'>
            <div className='item-price'>
              <span className='price-value'>{material.price} рублей за кг</span>
            </div>
          </div>
          <div className='control-buttons'>
            <Stack direction='row' spacing={1}>
              <IconButton
                onClick={() => onClickEditMaterialButton(material)}
                aria-label='edit product'
              >
                <EditIcon color='primary' />
              </IconButton>
            </Stack>
          </div>
        </div>
      ))}
    </div>
  )
}

export default EditProducts
