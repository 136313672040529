import React from 'react'

import './Preloader.scss'

const Preloader = () => (
  <div className='preloader-container'>
    <div className='preloader' />
  </div>
)

export default Preloader
