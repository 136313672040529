import React, { useEffect, useState } from 'react'
import MenuLinks from './MenuLinks'
import { useLocation, useNavigate } from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import LogoutIcon from '@mui/icons-material/Logout'

const Header = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [fullyVisible, setFullyVisible] = useState(true)

  const logout = () => {
    localStorage.removeItem('apiKey')
    navigate('/login')
  }

  useEffect(() => {
    if (
      location.pathname === '/login' ||
      location.pathname === '/admin-products-edit'
    ) {
      setFullyVisible(false)
    } else setFullyVisible(true)
  }, [location])

  return (
    <header
      className={`header header-top ${!fullyVisible && 'header-login'}`}
      style={
        !fullyVisible ? { paddingBottom: '2.5em' } : { paddingBottom: '1.9em' }
      }
    >
      <a href='/' className='logo logo-header'>
        {fullyVisible ? (
          <span className='logo-descriptor'>
            Производство мешков и&nbsp;пакетов из ПВД
          </span>
        ) : (
          <span className='logo-descriptor'>Администратор</span>
        )}
      </a>

      {fullyVisible ? (
        <div className='header-contents'>
          <nav className='menu'>
            <MenuLinks />
          </nav>

          <div className='phone-block'>
            <div className='phone-number'>
              <i className='fa fa-phone' aria-hidden='true'></i>
              +7 (3812) 633-654
            </div>
          </div>
        </div>
      ) : (
        location.pathname === '/admin-products-edit' && (
          <IconButton
            onClick={logout}
            aria-label='logout'
            className='header-logout-button'
          >
            <LogoutIcon htmlColor='white' />
          </IconButton>
        )
      )}
    </header>
  )
}

export default Header
