import React, { useRef, useState, useContext, useEffect } from 'react'

import LoadingContext from '../loading.context'

import logoUralPack from '../img/partner-logo-uralpack.png'
import logoTdPlastika from '../img/partner-logo-tdp.png'
import logoUpakMarket from '../img/partner-logo-upackmarket.png'
import cleanerWorldImg from '../img/cleaner-world.jpg'
import GoodsCatalog from './GoodsCatalog'
import Calculator from './Calculator'
import emailjs from '@emailjs/browser'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'

const MainPage = ({ data }) => {
  const [loading, setLoading] = useContext(LoadingContext)
  const [submitted, setSubmitted] = useState(false)

  const form1 = useRef()
  const form2 = useRef()
  const form3 = useRef()
  const form4 = useRef()
  const form5 = useRef()

  const [personalPolicyOpened, setPersonalPolicyOpened] = useState(false)
  const [askQuestionOpened, setAskQuestionOpened] = useState(false)
  const [orderProductOpened, setOrderProductOpened] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState('')
  const [name, setName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [message, setMessage] = useState('')
  const [formErrors, setFormErrors] = useState({
    name: '',
    phoneNumber: '',
    message: '',
  })

  const handleFormInput = (e) => {
    if (e) {
      // Phone
      let res
      if (typeof e === 'string') {
        const first2letters = e.substring(0, 2)
        if (first2letters === '+8' && e.length > 5) {
          const nextLetters = e.substring(2)
          res = '+7' + nextLetters
          setPhoneNumber(res)
        } else setPhoneNumber(e)

        if (isValidPhoneNumber(e)) {
          setFormErrors({ ...formErrors, phoneNumber: '' })
        } else if (!formErrors.phoneNumber.length) {
          setFormErrors({
            ...formErrors,
            phoneNumber: 'Invalid phone number',
          })
        }
        if (!phoneNumber.length && !formErrors.phoneNumber.length) {
          setFormErrors({
            ...formErrors,
            phoneNumber: 'Invalid phone number',
          })
        }
      } else {
        // Name
        if (e.target.name === 'fullname') {
          setName(e.target.value)
          if (!e.target.value.length) {
            setFormErrors({ ...formErrors, name: 'Name required' })
          } else setFormErrors({ ...formErrors, name: '' })
        } else {
          // Message
          setMessage(e.target.value)
          if (e.target.value.length > 250) {
            setFormErrors({ ...formErrors, message: 'Too long message' })
          } else setFormErrors({ ...formErrors, message: '' })
        }
      }
    }
  }

  const sendEmail = (e) => {
    if (e !== undefined) e.preventDefault()
    setLoading(true)

    const formRef = {
      form1: form1,
      form2: form2,
      form3: form3,
      form4: form4,
      form5: form5,
    }

    const currentForm = e.target.classList[e.target.classList.length - 1]

    const currentFormRef = formRef[currentForm]

    const resetForms = () => {
      setName('')
      setPhoneNumber('')
      setMessage('')
    }

    // Sending email with service called emailjs - https://www.emailjs.com/

    emailjs
      .sendForm(
        'service_8zvycvq',
        'template_chdemky',
        currentFormRef.current,
        'WLiZy0Vr45zpWHsq-'
      )
      .then(
        () => {
          console.log('Email is sent successfully')
          setSubmitted(true)
        },
        (error) => {
          console.log(error.text)
        }
      )
      .finally(() => {
        setLoading(false)
        setTimeout(function () {
          resetForms()
          setAskQuestionOpened(false)
          setOrderProductOpened(false)
          setSubmitted(false)
        }, 5000)
      })
  }

  const openOrderForm = (productName, form = 'product') => {
    setSelectedProduct(productName)
    if (form === 'question') {
      setAskQuestionOpened(true)
    } else {
      setOrderProductOpened(true)
    }
  }

  useEffect(() => {
    var elements = document.getElementsByTagName('INPUT')
    for (var i = 0; i < elements.length; i++) {
      elements[i].oninvalid = function (e) {
        e.target.setCustomValidity('')
        if (!e.target.validity.valid) {
          e.target.setCustomValidity('Пожалуйста заполните это поле')
        }
      }
      elements[i].oninput = function (e) {
        e.target.setCustomValidity('')
      }
    }
    localStorage.removeItem('apiKey')
  }, [])

  return (
    <>
      <section id='hero' className='mp-section mp-section-dark mp-hero'>
        <div className='mp-container'>
          <header className='mp-section-header'>
            <h1 className='mp-section-title mp-hero-title'>
              Мешки, пакеты и пленки из ПВД для любых целей от производителя за
              1 кг продукции от{' '}
              {data &&
                data.materials &&
                data.materials.length &&
                data.materials[0].price}{' '}
              р.
            </h1>
          </header>
          <div className='mp-section-content mp-hero-content'>
            <div className='mp-hero-content-info'>
              <div className='mp-hero-arrow'>
                <span>Получите доставку продукции за наш счет</span>
              </div>
              <ul className='mp-hero-product-list'>
                <li className='mp-hero-product-item'>Мешки для мусора</li>
                <li className='mp-hero-product-item'>Пакеты-майка</li>
                <li className='mp-hero-product-item'>
                  Патологоанатомические мешки
                </li>
              </ul>
            </div>

            <form
              ref={form1}
              onSubmit={sendEmail}
              className='form ajax-form mp-form mp-hero-form form1'
            >
              <h2 className='mp-form-title'>
                Отправьте заявку{' '}
                <span className='mp-form-title-format'>
                  и получите
                  <br /> бесплатную консультацию
                </span>
              </h2>
              <div className='mp-form-group'>
                <input
                  onChange={(e) => handleFormInput(e)}
                  value={name}
                  type='text'
                  className={`mp-form-control ${
                    formErrors.name.length && 'with-error'
                  }`}
                  placeholder='Ваше имя'
                  name='fullname'
                  required
                />
              </div>
              <div className='mp-form-group'>
                <PhoneInput
                  country='RU'
                  value={phoneNumber}
                  onChange={(e) => handleFormInput(e)}
                  className={`mp-form-control ${
                    formErrors.phoneNumber.length && 'with-error'
                  }`}
                  placeholder='Ваш телефон'
                  type='tel'
                  name='phone'
                  autoComplete='tel'
                  required
                />
              </div>
              <div className='mp-form-group mp-form-group-last'>
                <button
                  type='submit'
                  name='submit'
                  className='mp-button mp-button-block button-send'
                  disabled={
                    formErrors.name.length || formErrors.phoneNumber.length
                  }
                >
                  Отправить заявку
                </button>
              </div>
              <input type='text' name='name' className='absolute-input' />
              <p className='mp-form-info'>
                Нажимая на кнопку «Отправить заявку» вы даете согласие на
                обработку персональных данных
              </p>
              <div className='errors-container'></div>
              <div className='sending mp-sending hidden'>
                <h2>Отсылаем вашу заявку...</h2>
              </div>
            </form>
          </div>
        </div>
      </section>

      <section className='mp-section mp-work'>
        <div className='mp-container'>
          <header className='mp-section-header'>
            <h2 className='mp-section-title'>Ваш надежный партнер в работе</h2>
            <p className='mp-section-subtitle'>
              Будьте уверены, вы получаете надежного партнера
            </p>
          </header>
          <div className='mp-section-content'>
            <div className='row'>
              <div className='col col-l-4 col-m-4 col-xs-12'>
                <div className='mp-work-item'>
                  <div className='mp-work-item-icon'></div>
                  <h3 className='mp-work-item-title'>16 лет на рынке</h3>
                  <p className='mp-work-item-text'>
                    Мы давно завоевали доверие клиентов благодаря высокому
                    качеству и сервису.
                  </p>
                </div>
              </div>
              <div className='col col-l-4 col-m-4 col-xs-12'>
                <div className='mp-work-item'>
                  <div className='mp-work-item-icon mp-work-item-icon-calendar'></div>
                  <h3 className='mp-work-item-title'>
                    Сроки изготовления от 1 до 3 дней
                  </h3>
                  <p className='mp-work-item-text'>
                    Мы ценим время клиентов и выполняем заказ четко в срок.
                  </p>
                </div>
              </div>
              <div className='col col-l-4 col-m-4 col-xs-12'>
                <div className='mp-work-item'>
                  <div className='mp-work-item-icon mp-work-item-icon-money'></div>
                  <h3 className='mp-work-item-title'>Выгодные цены</h3>
                  <p className='mp-work-item-text'>
                    Мы являемся компанией производителем, 1 кг нашей продукции
                    от 130 р.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id='catalog' className='catalog-section mp-section mp-catalog'>
        <GoodsCatalog products={data.products} openOrderForm={openOrderForm} />
      </section>

      <Calculator materials={data.materials} />

      <section className='mp-section mp-section-dark mp-order'>
        <div className='mp-container'>
          <header className='mp-section-header'>
            <h2 className='mp-section-title'>Отличная цена! Заказывайте</h2>
          </header>
          <div className='mp-section-content'>
            <form
              ref={form2}
              onSubmit={sendEmail}
              className='form ajax-form mp-order-form'
            >
              <div className='row'>
                <div className='col col-l-8 col-s-8'>
                  <div className='mp-form-group'>
                    <PhoneInput
                      country='RU'
                      value={phoneNumber}
                      onChange={(e) => handleFormInput(e)}
                      className={`mp-form-control ${
                        formErrors.phoneNumber.length && 'with-error'
                      }`}
                      placeholder='Ваш телефон'
                      type='tel'
                      name='phone'
                      autoComplete='tel'
                      required
                    />
                  </div>
                </div>
                <input type='text' name='name' className='absolute-input' />
                <div className='col col-l-4 col-s-4'>
                  <div className='mp-form-group'>
                    <button
                      type='submit'
                      name='submit'
                      className='mp-button mp-button-block button-send'
                      disabled={formErrors.phoneNumber.length}
                    >
                      Заказать мешок
                    </button>
                  </div>
                </div>
              </div>
              <div className='errors-container'></div>
              <div className='sending mp-sending hidden'>
                <h2>Отсылаем вашу заявку...</h2>
              </div>
            </form>
            <p className='mp-order-info'>
              Нажимая на кнопку «Заказать мешок» Вы даете согласие на обработку
              персональных данных
            </p>
            <span className='mp-order-info-or'>Или позвоните нам</span>
            <div className='mp-order-phone-wrap'>
              <a className='mp-order-phone' href='tel:+73812633654'>
                +7 (3812) 633-654
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className='mp-section mp-section-dark mp-clear'>
        <div className='mp-container'>
          <div className='row'>
            <div className='col col-l-6 col-s-6'>
              <h2 className='mp-section-title mp-clear-title'>
                Сделаем мир чище!
              </h2>
              <p className='mp-clear-text'>
                На полное разложение полиэтилена в естественных природных
                условиях уходит около трехсот лет. Поэтому мы используем только
                вторичный полиэтилен высокого давления, который не только
                отличается хорошей стойкостью к разрывам и ударам, но и помогает
                решать целый комплекс экологических проблем.
              </p>
            </div>
            <div className='col col-l-6 col-s-6'>
              <img className='mp-clear-img' src={cleanerWorldImg} alt='' />
            </div>
          </div>
        </div>
      </section>

      <section id='feedback' className='mp-section mp-partners'>
        <div className='mp-container'>
          <header className='mp-section-header'>
            <h2 className='mp-section-title'>Отзывы наших партнеров</h2>
          </header>
          <div className='mp-section-content'>
            <div className='row'>
              <div className='col col-l-4 col-m-4'>
                <div className='mp-partners-item'>
                  <div className='mp-partners-item-logo'>
                    <img
                      src={logoUralPack}
                      alt='УралПак'
                      className='mp-partners-item-logo-img'
                    />
                  </div>
                  <p className='mp-partners-item-info'>
                    Берем мешки для уборки территории и других хозяйственных
                    нужд на реализацию. Исполняют заказы быстро, нареканий по
                    качеству нет. Наши клиенты довольны, а мы довольны своим
                    поставщиком.
                  </p>
                  <p className='mp-partners-item-info-person'>
                    Директор закупок Колясников А. «Уралпак»
                  </p>
                </div>
              </div>
              <div className='col col-l-4 col-m-4'>
                <div className='mp-partners-item'>
                  <div className='mp-partners-item-logo'>
                    <img
                      src={logoTdPlastika}
                      alt='Торговый Дом Пластика'
                      className='mp-partners-item-logo-img'
                    />
                  </div>
                  <p className='mp-partners-item-info'>
                    Давно сотрудничаем с «МагПак». Это профессионалы, которые
                    уважают Заказчика и работают на совесть. Никогда не
                    заставляли ждать поставку дольше обещанного. Почти все в
                    наличии, прайс не завышен. Процветания вашей компании!
                  </p>
                  <p className='mp-partners-item-info-person'>
                    Максимова Юля, инженер отдела закупок ТД «Пластика»
                  </p>
                </div>
              </div>
              <div className='col col-l-4 col-m-4'>
                <div className='mp-partners-item'>
                  <div className='mp-partners-item-logo'>
                    <img
                      src={logoUpakMarket}
                      alt='УпакМаркет'
                      className='mp-partners-item-logo-img'
                    />
                  </div>
                  <p className='mp-partners-item-info'>
                    Наш надежный партнер по бизнесу. Ни одного сорванного срока.
                    Всегда идут на встречу и решают любой вопрос. Цены
                    оптимальные по соотношению с качеством – это обуславливается
                    собственным производством. Контроль на каждом этапе и как
                    результат – достойная продукция. Рад нашему взаимовыгодному
                    сотрудничеству!
                  </p>
                  <p className='mp-partners-item-info-person'>
                    Генеральный директор Ромащенко Е.О ООО «Упак-маркет»
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='mp-section mp-section-dark mp-call-us'>
        <div className='mp-container'>
          <header className='mp-section-header'>
            <h2 className='mp-section-title'>
              Остались вопросы? Позвоните нам!
            </h2>
          </header>
          <div className='mp-section-content'>
            <div className='mp-order-phone-wrap'>
              <a className='mp-order-phone' href='tel:+73812633654'>
                +7 (3812) 633-654
              </a>
            </div>
            <span className='mp-order-info-or'>Или закажите звонок</span>
            <form
              ref={form3}
              onSubmit={sendEmail}
              className='form ajax-form mp-call-us-form'
            >
              <div className='row'>
                <div className='col col-l-8 col-s-8'>
                  <div className='mp-form-group'>
                    <PhoneInput
                      country='RU'
                      value={phoneNumber}
                      onChange={(e) => handleFormInput(e)}
                      className={`mp-form-control ${
                        formErrors.phoneNumber.length && 'with-error'
                      }`}
                      placeholder='Ваш телефон'
                      type='tel'
                      name='phone'
                      autoComplete='tel'
                      required
                    />
                  </div>
                </div>
                <input type='text' name='name' className='absolute-input' />
                <div className='col col-l-4 col-s-4'>
                  <div className='mp-form-group'>
                    <button
                      type='submit'
                      name='submit'
                      className='mp-button mp-button-block button-send'
                      disabled={formErrors.phoneNumber.length}
                    >
                      Заказать мешок
                    </button>
                  </div>
                </div>
              </div>
              <div className='errors-container'></div>
              <div className='sending mp-sending hidden'>
                <h2>Отсылаем вашу заявку...</h2>
              </div>
            </form>
            <p className='mp-call-us-info'>
              Нажимая на кнопку «Заказать мешок» Вы даете согласие на обработку
              персональных данных
            </p>
          </div>
        </div>
      </section>

      <div id='contacts' className='mp-contacts'>
        <div id='mymap' className='mp-contacts-map'></div>
        <div className='mp-container'>
          <div className='mp-contacts-content'>
            <div className='mp-contacts-info'>
              <h2 className='mp-contacts-title'>Контакты</h2>
              <h3 className='mp-contacts-company'>Компания «МагПак»</h3>
              <p>Наш адрес: г. Омск ул. проспект Культуры, 17 644029</p>
              <p>
                Телефон:{' '}
                <a className='mp-link' href='tel:+79609949280'>
                  +7 960 994 9280
                </a>
                <br />
                Телефон:{' '}
                <a className='mp-link' href='tel:+79609894602'>
                  +7 960 989 4602
                </a>
              </p>
              <p>
                Электронная почта:{' '}
                <a className='mp-link' href='mailto:mailamur@mail.ru'>
                  mailamur@mail.ru
                </a>
              </p>
              <p>График работы: Ежедневно с 10:00 до 19:00</p>
              <p>ОГРН: 304550723900131</p>
            </div>
            <p className='mp-contacts-copyright'>
              <span>Copyright © {new Date().getFullYear()}</span>
              <br />
              <span
                className='mp-link mp-link-underline confidential-button'
                onClick={() => setPersonalPolicyOpened(true)}
              >
                Политика конфиденциальности
              </span>
            </p>
          </div>
        </div>
        <script
          type='text/javascript'
          charSet='utf-8'
          async
          src='https://api-maps.yandex.ru/services/constructor/1.0/js/?um=constructor%3A15fd3b5bcb20b46c2989782bc6d85e045a509317bf856ed56fa100d8a8ead5fd&amp;id=mymap&amp;lang=ru_RU&amp;scroll=false'
        ></script>
      </div>

      <div
        className={`popup-overlay close-popup popup-overlay-product ${
          orderProductOpened && 'popup-opened'
        }`}
      >
        <div className='popup-form order-call-form'>
          <span
            onClick={() => setOrderProductOpened(false)}
            className='close-popup'
          >
            &times;
          </span>
          <form
            ref={form4}
            onSubmit={sendEmail}
            id='contact-form'
            className='form ajax-form mp-form mp-popup-form form4'
          >
            <div className={`init ${(loading || submitted) && 'hidden'}`}>
              <h2 className='mp-form-title'>Заказать товар</h2>

              <div className='mp-form-group'>
                <input
                  onChange={(e) => handleFormInput(e)}
                  value={name}
                  type='text'
                  className={`mp-form-control ${
                    formErrors.name.length && 'with-error'
                  }`}
                  placeholder='Ваше имя'
                  name='fullname'
                  required
                />
              </div>
              <div className='mp-form-group'>
                <PhoneInput
                  country='RU'
                  value={phoneNumber}
                  onChange={(e) => handleFormInput(e)}
                  className={`mp-form-control ${
                    formErrors.phoneNumber.length && 'with-error'
                  }`}
                  placeholder='Ваш телефон'
                  type='tel'
                  name='phone'
                  autoComplete='tel'
                  required
                />
              </div>
              <div className='mp-form-group'>
                <div className='current-item-name'>{selectedProduct}</div>
                <input
                  type='hidden'
                  name='product'
                  value={selectedProduct}
                  required
                />
              </div>
              <div className='mp-form-group'>
                <textarea
                  onChange={(e) => handleFormInput(e)}
                  value={message}
                  className={`mp-form-control ${
                    formErrors.message.length && 'with-error'
                  }`}
                  rows='3'
                  placeholder='Ваше сообщение'
                  name='message'
                ></textarea>
              </div>

              <input type='text' name='name' className='absolute-input' />

              <div className='errors-container'></div>

              <div className='mp-form-group mp-form-group-last'>
                <button
                  type='submit'
                  name='submit'
                  id='submit'
                  className='mp-button mp-button-block button-send'
                  disabled={
                    formErrors.name.length ||
                    formErrors.phoneNumber.length ||
                    formErrors.message.length
                  }
                >
                  Отправить
                </button>
              </div>
              <p className='mp-form-info'>
                Нажимая на кнопку «Отправить заявку» вы даете согласие на
                обработку персональных данных
              </p>
            </div>
            <div className={`sending ${loading ? '' : 'hidden'}`}>
              <h2>Отсылаем вашу заявку</h2>
            </div>
            <div className={`finished ${submitted ? '' : 'hidden'}`}>
              <h2>Спасибо за вашу заявку!</h2>
              <p>Наши специалисты свяжутся с вами в течении получаса</p>
            </div>
          </form>
        </div>
      </div>

      <div
        className={`popup-overlay close-popup popup-overlay-product ${
          askQuestionOpened && 'popup-opened'
        }`}
      >
        <div className='popup-form order-product'>
          <span
            onClick={() => setAskQuestionOpened(false)}
            className='close-popup'
          >
            &times;
          </span>
          <form
            ref={form5}
            onSubmit={sendEmail}
            id='contact-form'
            className='form ajax-form mp-form mp-popup-form form5'
          >
            <div className={`init ${(loading || submitted) && 'hidden'}`}>
              <h2 className='mp-form-title mp-form-title-question'>
                Задать вопрос
              </h2>
              <p className='mp-form-subtitle'>
                Менеджеры компании с радостью ответят на ваши вопросы и
                произведут расчет стоимости услуг и подготовят индивидуальное
                коммерческое предложение.
              </p>

              <div className='mp-form-group'>
                <input
                  onChange={(e) => handleFormInput(e)}
                  type='text'
                  className={`mp-form-control ${
                    formErrors.name.length && 'with-error'
                  }`}
                  placeholder='Ваше имя'
                  name='fullname'
                  required
                />
              </div>
              <div className='mp-form-group'>
                <PhoneInput
                  country='RU'
                  value={phoneNumber}
                  onChange={(e) => handleFormInput(e)}
                  className={`mp-form-control ${
                    formErrors.phoneNumber.length && 'with-error'
                  }`}
                  placeholder='Ваш телефон'
                  type='tel'
                  name='phone'
                  autoComplete='tel'
                  required
                />
              </div>
              <div className='mp-form-group'>
                <div className='current-item-name'>{selectedProduct}</div>
                <input
                  type='hidden'
                  name='product'
                  value={selectedProduct}
                  required
                />
              </div>
              <div className='mp-form-group'>
                <textarea
                  className={`mp-form-control ${
                    formErrors.message.length && 'with-error'
                  }`}
                  rows='3'
                  placeholder='Ваше сообщение'
                  name='message'
                ></textarea>
              </div>

              <input type='text' name='name' className='absolute-input' />

              <div className='errors-container'></div>

              <div className='mp-form-group mp-form-group-last'>
                <button
                  type='submit'
                  name='submit'
                  id='submit'
                  className='mp-button mp-button-block button-send'
                  disabled={
                    formErrors.name.length ||
                    formErrors.phoneNumber.length ||
                    formErrors.message.length
                  }
                >
                  Отправить
                </button>
              </div>
              <p className='mp-form-info'>
                Нажимая на кнопку «Отправить заявку» вы даете согласие на
                обработку персональных данных
              </p>
            </div>
            <div className={`sending ${loading ? '' : 'hidden'}`}>
              <h2>Отсылаем вашу заявку</h2>
            </div>
            <div className={`finished ${submitted ? '' : 'hidden'}`}>
              <h2>Спасибо за вашу заявку!</h2>
              <p>Наши специалисты свяжутся с вами в течении получаса</p>
            </div>
          </form>
        </div>
      </div>

      <div
        className={`popup-overlay close-popup popup-overlay-confidential ${
          personalPolicyOpened && 'popup-opened'
        }`}
      >
        <div className='popup-form popup-confidential-information'>
          <span
            className='close-popup'
            onClick={() => setPersonalPolicyOpened(false)}
          >
            &times;
          </span>
          <div className='mp-form mp-popup-form confidential-information-popup'>
            <h2 className='mp-section-title'>Политика конфиденциальности</h2>
            <p>
              Присоединяясь к настоящему Соглашению и оставляя свои данные на
              Сайте magpack.online, Пользователь:
            </p>
            <ul>
              <li>
                подтверждает, что все указанные им данные принадлежат лично ему;
              </li>
              <li>
                подтверждает и признает, что им внимательно в полном объеме
                прочитано Соглашение и условия обработки его персональных
                данных;
              </li>
              <li>
                подтверждает, что текст соглашения и условия обработки
                персональных данных ему понятны;
              </li>
              <li>
                дает согласие на обработку Сайтом предоставляемых в составе
                информации персональных данных в целях заключения между ним и
                Сайтом настоящего Соглашения, а также его последующего
                исполнения;
              </li>
              <li>
                выражает согласие с условиями обработки персональных данных без
                оговорок и ограничений.
              </li>
            </ul>
            <p>
              Пользователь дает свое согласие на обработку его персональных
              данных, а именно совершение действий, предусмотренных п. 3 ч. 1
              ст. 3 Федерального закона от 27.07.2006 N 152-ФЗ "О персональных
              данных", и подтверждает, что, давая такое согласие, он действует
              свободно, своей волей и в своем интересе. Согласие Пользователя на
              обработку персональных данных является конкретным, информированным
              и сознательным.
            </p>
            <p>
              Настоящее согласие Пользователя распространяется на следующие
              персональные данные: фамилия, имя и отчество; номер телефона;
              адрес электронной почты.
            </p>
            <p>
              Пользователь, предоставляет Cайту право осуществлять следующие
              действия (операции) с персональными данными: сбор и накопление;
              хранение в течение установленных нормативными документами сроков
              хранения отчетности, но не менее трех лет, с момента даты
              прекращения пользования услуг Пользователем; уточнение
              (обновление, изменение); использование; уничтожение;
              обезличивание; передача по требованию суда, в т.ч., третьим лицам,
              с соблюдением мер, обеспечивающих защиту персональных данных от
              несанкционированного доступа.
            </p>
            <p>
              Указанное согласие действует бессрочно с момента предоставления
              данных и может быть отозвано Вами путем направления Пользователем
              соответствующего распоряжения в простой письменной форме на адрес
              электронной почты -
              <a href='mailto:mailamur@mail.ru' className='link'>
                mailamur@mail.ru
              </a>
              .
            </p>
            <p>
              Сайт имеет право вносить изменения в настоящее Соглашение. При
              внесении изменений в актуальной редакции указывается дата
              последнего обновления. Новая редакция Соглашения вступает в силу с
              момента ее размещения, если иное не предусмотрено новой редакцией
              Соглашения.
            </p>
            <p>
              К настоящему Соглашению и отношениям между пользователем и Сайтом,
              возникающим в связи с применением Соглашения подлежит применению
              право Российской Федерации.
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default MainPage
