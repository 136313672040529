const formatPrice = function (price) {
  let result = price.toString()
  if (result.split('.').length === 1) {
    result += '.00'
  } else {
    if (result.split('.')[1].length === 1) {
      result += '0'
    }
  }

  return result.replace(/\./g, '-') + ' ₽'
}

export default formatPrice
