import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { useData } from './hooks/useData'
import { useEffect, useState } from 'react'

import LoadingContext from './loading.context'

import Header from './components/Header'
import MainPage from './components/MainPage'
import LoginForm from './components/LoginForm'
import EditProducts from './components/EditProducts'
import NotFound from './components/NotFound'
import Footer from './components/Footer'
import Preloader from './components/Preloader/Preloader'

import './scss/App.scss'

function App() {
  const { data } = useData()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!localStorage.getItem('apiKey')) localStorage.setItem('apiKey', '')
  }, [])

  return (
    <Router>
      <LoadingContext.Provider value={[loading, setLoading]}>
        <div className='site with-order'>
          <Header />

          <main>
            <Routes>
              <Route path='/' element={<MainPage data={data} />} />
              <Route path='/login' element={<LoginForm />} />
              <Route path='/admin-products-edit' element={<EditProducts />} />
              <Route path='/*' element={<NotFound />} />
            </Routes>
          </main>

          <Footer />
        </div>

        {loading && <Preloader />}
      </LoadingContext.Provider>
    </Router>
  )
}

export default App
