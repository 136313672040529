import React, { useState } from 'react'

import imageSumka from '../img/item-sumka.jpg'
import imageMusornyiMeshok1 from '../img/item-photo-1.jpg'
import imageMusornyiMeshok2 from '../img/item-photo-2.jpg'
import imageMaika from '../img/item-maika.jpg'
import imageMaikaColor from '../img/item-maika-col.jpg'
import imageShtory from '../img/item-shtorka.jpg'
import imagePatMeshok from '../img/item-meshok-patolog.jpeg'

const GoodsCatalog = ({ products, openOrderForm }) => {
  const [cropped, setCropped] = useState(true)

  const getProductImage = (product) => {
    if (product.main_name.includes('Сумка')) {
      return imageSumka
    } else if (product.main_name.includes('мусора')) {
      if (Math.random() > 0.5) return imageMusornyiMeshok1
      else return imageMusornyiMeshok2
    } else if (product.main_name.includes('Пакет-майка')) {
      if (product.main_name.includes('цветной')) return imageMaikaColor
      else return imageMaika
    } else if (product.main_name.includes('Шторы')) {
      return imageShtory
    } else {
      return imagePatMeshok
    }
  }

  return (
    <div className='mp-container'>
      <header className='mp-section-header'>
        <h2 className='mp-section-title'>Каталог продукции</h2>
        <p className='mp-section-subtitle'>
          Оставьте заявку на товар, и наш менеджер перезвонит вам для уточнения
          деталей.
        </p>
      </header>

      <div
        id='loadList'
        className={`row mp-product-list ${cropped ? 'mp-cropped' : ''}`}
      >
        {products.map((product) => (
          <div key={product._id} className='col col-l-6 col-s-12 card item'>
            <div className='item-photo'>
              <img src={getProductImage(product)} alt='product' />
            </div>
            <div className='item-description'>
              <h4>{product.main_name}</h4>
              <div className='item-properties'>{product.properties}</div>
              <div className='item-price'>
                {product.item_price}
                <span className='price-value' style={{ display: 'none' }}>
                  {product.price}
                </span>
              </div>
              <div className='control-buttons'>
                <div className='bottom-part'>
                  <div className='row'>
                    <div className='col col-l-6'>
                      <button
                        onClick={() =>
                          openOrderForm(product.main_name, 'question')
                        }
                        className='mp-button mp-button-small mp-button-block contact-button'
                      >
                        Задать вопрос
                      </button>
                    </div>
                    <div className='col col-l-6'>
                      <button
                        onClick={() => openOrderForm(product.main_name)}
                        className='mp-button mp-button-small mp-button-block order-button'
                      >
                        Заказать товар
                      </button>
                    </div>
                  </div>
                  <span className='summa mp-summa'>
                    <span className='summa-value'>{product.item_price}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className='mp-catalog-footer'>
        <span
          id='loadMore'
          className='mp-link mp-link-underline mp-load-more'
          onClick={() => setCropped(!cropped)}
        >
          {cropped ? 'Смотреть больше товара' : 'Свернуть список'}
        </span>
      </div>
    </div>
  )
}

export default GoodsCatalog
