import React from 'react'

const MenuLinks = () => {
  return (
    <ul className='menu-links'>
      <li>
        <a href='/#' className='menu-link'>
          В начало
        </a>
      </li>
      <li>
        <a href='/#catalog' className='menu-link'>
          Каталог продукции
        </a>
      </li>
      <li>
        <a href='/#feedback' className='menu-link'>
          Отзывы
        </a>
      </li>
      <li>
        <a href='/#contacts' className='menu-link'>
          Контакты
        </a>
      </li>
      <li>
        <a href='/#calculator-of-price' className='menu-link'>
          Мешки по вашим размерам
        </a>
      </li>
    </ul>
  )
}

export default MenuLinks
