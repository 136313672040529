import React, { useContext } from 'react'
import Button from '@mui/material/Button'
import LoadingContext from '../loading.context'

const DeleteProduct = ({
  modalOpened,
  setModalOpened,
  deleteProduct,
  product,
  updateProducts,
}) => {
  const setLoading = useContext(LoadingContext)[1]

  const onConfirmDelete = () => {
    const id = product._id
    setLoading(true)

    deleteProduct(id).then(() => {
      updateProducts()
      setLoading(false)
      setModalOpened(false)
    })
  }

  return (
    <div
      className={`popup-overlay close-popup ${modalOpened && 'popup-opened'}`}
    >
      <div className='popup-form product-delete-prompt'>
        <span onClick={() => setModalOpened(false)} className='close-popup'>
          &times;
        </span>

        <div className='container'>
          <div className='form'>
            <div className='form-row'>
              <h4>Вы уверены что хотите удалить</h4>
              <p>
                <em>{product.main_name}?</em>
              </p>
            </div>

            <div className='buttons-centered'>
              <Button
                onClick={onConfirmDelete}
                variant='contained'
                type='submit'
                color='error'
              >
                Удалить
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DeleteProduct
