import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import LoadingContext from '../loading.context'
import formatPriceString from '../utils/formatPriceString'

const EditProductForm = ({
  modalOpened,
  setModalOpened,
  createProduct,
  updateProduct,
  product = null,
  updateProducts,
  material = false,
  updateMaterial
}) => {
  const setLoading = useContext(LoadingContext)[1]

  const [appeared, setAppeared] = useState(false)

  const {
    register,
    handleSubmit,
    formState,
    getValues,
    setValue,
    trigger,
    reset,
  } = useForm({
    defaultValues: {
      id: product._id || null,
      main_name: product.main_name || '',
      properties: product.properties || '',
      item_price: product.item_price || '',
      item_image: product.item_image || '',
      price: product.price || '',
    },
  })

  const onSubmit = async (data) => {
    await setLoading(true)
    // Preparing Trimming of extra spaces
    await setValue('price', data.price.toString())
    await setValue('main_name', data.main_name.trim())
    await setValue('properties', data.properties.trim())

    data.price = getValues('price').toString()
    data.item_image = ''
    data.item_price = formatPriceString(data.price)

    // Create product
    if (product && !product._id) {
      reset()
      delete data.id
      await createProduct(data)
    }
    // Update material
    if (product && typeof product._id === 'string' && material) {
      data.id = product._id
      delete data.main_name
      delete data.properties
      delete data.item_price
      delete data.item_image

      await updateMaterial(data)
    }
    // Update product
    if (product && typeof product._id === 'string' && !material) {
      data.id = product._id
      await updateProduct(data)
    }
    updateProducts()
    setLoading(false)
    reset()
    setModalOpened(false)
  }

  const validatePriceInput = (e) => {
    const letters = /^[A-Za-z]+$/
    if (
      e.key !== 'Backspace' &&
      e.key !== 'Meta' &&
      e.key !== 'Shift' &&
      e.key !== 'ArrowLeft' &&
      e.key !== 'ArrowRight' &&
      e.key !== 'Enter' &&
      e.key !== 'Alt'
    ) {
      if (e.key.toString().match(letters)) {
        e.preventDefault()
      }
      if (e.target.value[e.target.value.length - 1] === ',') {
        e.preventDefault()
        let newVal = e.target.value.replace(/.$/, '.')
        setValue('price', newVal.toString())
      }
      // Checking if there is not more than 2 characters after "."
      if (e.target.value.split('.').length > 1) {
        if (e.target.value.split('.')[1].length >= 2) {
          e.preventDefault()
        }
      }
    }
  }

  const validateForm = () => {
    trigger()
  }

  const onCloseButtonClicked = () => {
    setAppeared(false)
    setTimeout(() => {
      if (modalOpened) setModalOpened(false)
    }, 600)
  }

  useEffect(() => {
    if (product === null) {
      setValue('main_name', '')
      setValue('properties', '')
      setValue('price', '')
    }
  }, [setValue, product])

  useEffect(() => {
    setTimeout(() => {
      if (modalOpened) setAppeared(modalOpened)
    }, 0)
  }, [modalOpened])

  return (
    <div className={`popup-overlay close-popup ${appeared && 'popup-opened'}`}>
      <div className='popup-form product-modal-form'>
        <span onClick={onCloseButtonClicked} className='close-popup'>
          &times;
        </span>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='container'>
            <div className='form'>
              <h4>
                {typeof product._id === 'string' ? 'Редактировать' : 'Создать'}
              </h4>

              {material ? (
                ''
              ) : (
                <>
                  <div className='form-row'>
                    <TextField
                      {...register('main_name', {
                        required: true,
                        maxLength: 150,
                      })}
                      defaultValue={getValues('main_name').trim()}
                      label='Наименование'
                      variant='standard'
                      onKeyUp={validateForm}
                    />
                  </div>
                  <div className='form-row'>
                    <TextField
                      {...register('properties', {
                        required: true,
                        maxLength: 150,
                      })}
                      label='Описание'
                      variant='standard'
                      onKeyUp={validateForm}
                    />
                  </div>
                </>
              )}
              <div className='form-row'>
                <TextField
                  {...register('price', {
                    required: true,
                    valueAsNumber: true,
                    min: 0.09,
                    max: 10000000.99,
                    step: 0.01,
                  })}
                  label='Цена'
                  variant='standard'
                  onKeyDownCapture={(e) => validatePriceInput(e)}
                  onKeyUp={validateForm}
                />
              </div>

              <div className='buttons-centered'>
                <Button
                  variant='contained'
                  type='submit'
                  disabled={Object.values(formState.errors).length > 0}
                >
                  Сохранить
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default EditProductForm
